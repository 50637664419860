import cn from 'classnames';
import YouTube from 'react-youtube';

import { CrossIcon } from '@/assets/Icons';

import styles from './YoutubeFrame.module.scss';

interface Props {
  isFrameActive: boolean;
  toggleFrame: () => void;
  idVideo?: string | null;
}

const YoutubeFrame = ({ isFrameActive, toggleFrame, idVideo = '4bvGcavFoqo' }: Props) => {
  const onReady = event => {
    event.target.playVideo();
  };

  const opts = {
    height: 'auto',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <section>
      <button className={styles.frame__close}>
        <CrossIcon stroke="#0041ff" />
      </button>
      <div
        className={cn(styles.background, { [styles['background--active']]: isFrameActive })}
        role="button"
        tabIndex={1}
        onClick={toggleFrame}
      />
      <YouTube
        videoId={`${idVideo}?autoplay=1`}
        onReady={onReady}
        opts={opts}
        iframeClassName={cn(styles.frame, {
          [styles['frame--played']]: isFrameActive,
        })}
      />
    </section>
  );
};

export default YoutubeFrame;
