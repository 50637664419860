'use client';
import { useRef, useState } from 'react';

import cn from 'classnames';
import { Autoplay, Navigation } from 'swiper';
import type { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SecondSwiperArrowIcon } from '@/assets/Icons';
import { MainPageAnimatedCardType } from '@/src/domain/main/MainPageAnimatedCardType';
import { widthBreakpoints } from '@/src/hooks/useResize';

import VideoCard from '../VideoCard';

import styles from './AnimatedCardsSection.module.scss';

const breakpoints = {
  2900: {
    slidesPerView: 8,
    slidesPerGroup: 8,
    spaceBetween: 28,
  },
  [widthBreakpoints.desktop_wide + 1]: {
    slidesPerView: 7,
    slidesPerGroup: 7,
    spaceBetween: 28,
  },
  2200: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 28,
  },
  [widthBreakpoints.desktop_xl - 50]: {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 28,
  },
  [widthBreakpoints.desktop_s + 1]: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 20,
  },
  [905]: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 20,
  },
  [490]: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  [widthBreakpoints.mobile_l + 1]: {
    slidesPerView: 1.6,
    slidesPerGroup: 1.6,
    spaceBetween: 20,
  },
  [widthBreakpoints.mobile_s + 1]: {
    slidesPerView: 1.4,
    slidesPerGroup: 1.4,
    spaceBetween: 20,
  },
  [200]: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
  },
};

const AnimatedCardsSection = ({ animatedCards }: { animatedCards: MainPageAnimatedCardType[] }) => {
  const [swiperArrowsDisabledState, setSwiperArrowDisabledState] = useState({
    isStart: true,
    isEnd: false,
  });
  const [isSwiperLoaded, setIsSwiperLoaded] = useState(false);
  const swiperRef = useRef<SwiperType | null>(null);

  const goPrev = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slideNext();
    }
  };

  const onIndexChange = (swiper: SwiperType) => {
    setTimeout(() => {
      setIsSwiperLoaded(true);
    }, 0);
    const isEnd = swiper.isEnd;
    const isStart = swiper.isBeginning;

    if (isEnd && !isStart) {
      setSwiperArrowDisabledState({ isStart: false, isEnd: true });
      return;
    }
    if (isStart && !isEnd) {
      setSwiperArrowDisabledState({ isStart: true, isEnd: false });
      return;
    }

    setSwiperArrowDisabledState({ isStart: true, isEnd: true });
  };

  return (
    <div className={styles.cards}>
      <Swiper
        style={{
          position: isSwiperLoaded ? 'initial' : 'absolute',
          opacity: isSwiperLoaded ? 1 : 0,
        }}
        className={styles.cards__swiper}
        centerInsufficientSlides
        breakpoints={breakpoints}
        modules={[Autoplay, Navigation]}
        speed={1100}
        onActiveIndexChange={onIndexChange}
        onInit={onIndexChange}
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: false,
        // }}
        onBeforeInit={(swiper?: SwiperType) => {
          if (swiper) {
            swiperRef.current = swiper;
          }
        }}
      >
        {animatedCards?.map(card => (
          <SwiperSlide className={styles.cards__swiper_card} key={card.id}>
            <VideoCard key={card.id} {...card} />
          </SwiperSlide>
        ))}
        {!swiperArrowsDisabledState.isStart && (
          <button
            aria-label="Prev slide"
            className={cn(styles.swiper_button, styles['swiper_button--prev'])}
            onClick={goPrev}
          >
            <SecondSwiperArrowIcon />
          </button>
        )}
        {!swiperArrowsDisabledState.isEnd && (
          <button
            aria-label="Next slide"
            className={cn(styles.swiper_button, styles['swiper_button--next'])}
            onClick={goNext}
          >
            <SecondSwiperArrowIcon />
          </button>
        )}
      </Swiper>
      <div
        className={styles.skeleton_section}
        style={{ display: isSwiperLoaded ? 'none' : 'grid' }}
      >
        <div className={styles.skeleton_section__card} />
      </div>
    </div>
  );
};

export default AnimatedCardsSection;
